<template>
  <div class="sidebar" data-t="main-menu">
    <div class="sticky-content">
      <TournamentMenuItem v-if="ratRaceWidget" :card="ratRaceWidget" />
      <button class="search" @click="openSearchModal">
        <StInput
          :placeholder="t('search.buttonPlacholder')"
          class="search-activator"
          icon-prefix="search"
          size="s"
          data-t="sidebar-search-input-9jiz"
          @focus="openSearchModal"
        />
      </button>
    </div>
    <div class="content">
      <section class="menu">
        <StSidebarList :level="1">
          <CasinoMenu
            :model-value="openedMenu === 'casino'"
            @update:model-value="onExpand"
          />
          <SportMenu
            :model-value="openedMenu === 'sport'"
            @update:model-value="onExpand"
          />
          <CybersportMenu
            :model-value="openedMenu === 'cybersport'"
            @update:model-value="onExpand"
          />
        </StSidebarList>
      </section>

      <section class="menu">
        <StSidebarList :level="1">
          <StSidebarItem
            v-for="item in sidebar2Menu"
            :key="item.label"
            :label="item.label"
            :image="item.image"
            :level="0"
            :link="item.link"
            :counter="item.counter"
          />
        </StSidebarList>
      </section>

      <section class="menu">
        <StSidebarList :level="1">
          <StSidebarItem
            v-for="item in sidebar3Menu"
            :id="item.id"
            :key="item.label"
            :label="item.label"
            :icon="item.icon"
            :level="0"
            :link="item.link"
            :target-blank="item.newWindow"
            :ignore-active-state="item.ignoreActiveState"
          />
        </StSidebarList>
      </section>
      <section class="menu">
        <SidebarPwaWidget v-if="isWeb" />
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBonusesCountStore } from '@st/bonuses/stores/useBonusesCountStore'
import type { IconName } from '@st/ui/components/StIcon/types'
import SidebarPwaWidget from '@st/pwa/components/SidebarPwaWidget/SidebarPwaWidget.vue'
import { useUserStore } from '@st/user/stores/useUserStore'
import vip from './img/vip.png'
import tournament from './img/tournament.png'
import bonus from './img/bonus.png'
import coins from './img/coins.png'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { isWeb } = useApplicationType()
const { supportUrl } = useSupportUrl()
const { bonusesCount } = storeToRefs(useBonusesCountStore())
const { isAuthenticated } = storeToRefs(useUserStore())

type OpenedMenuItem = 'casino' | 'sport' | 'cybersport' | null

const openedMenu = ref<OpenedMenuItem>(null)

function onExpand(val: OpenedMenuItem | null) {
  openedMenu.value = val
}

function openSearchModal() {
  router.replace({ query: { modal: 'search' } })
}

const { isEnabledTournaments } = useEnabledTournaments()

watchEffect(() => {
  if (route.path.match('casino')) {
    openedMenu.value = 'casino'
  } else if (route.path.match('cybersport')) {
    openedMenu.value = 'cybersport'
  } else if (route.path.match('sport')) {
    openedMenu.value = 'sport'
  } else openedMenu.value = null
})

const sidebar2Menu = computed(() =>
  [
    {
      label: t('menu.bonuses'),
      image: bonus,
      link: isAuthenticated.value ? '/bonuses' : '/bonus-info',
      counter: bonusesCount.value ?? 0,
      isVisible: true,
    },
    {
      label: t('menu.earn'),
      image: coins,
      link: '/refer-and-earn',
      isVisible: true,
    },
    {
      label: t('menu.VIPclub'),
      image: vip,
      link: '#',
      isVisible: false,
    },
    {
      label: t('menu.tournaments'),
      image: tournament,
      link: '/tournaments',
      isVisible: isEnabledTournaments.value,
    },
  ].filter(({ isVisible }) => Boolean(isVisible)),
)

const sidebar3Menu = computed(() => [
  {
    label: t('menu.faq'),
    icon: 'book-solid' as IconName,
    link: supportUrl.value,
    id: 'faq',
    newWindow: true,
  },
  {
    label: t('menu.liveChat'),
    icon: 'support-solid' as IconName,
    link: route.path,
    id: 'intercomButton',
    ignoreActiveState: true,
  },
])

const { tournament: ratRaceWidget } = usePageTournament('all')
</script>

<style scoped>
.sidebar {
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);

  padding-bottom: var(--spacing-200);
}

.sticky-content {
  position: sticky;
  z-index: 1;
  top: 0;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: var(--spacing-050);
}

.content {
  scrollbar-width: none;

  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  gap: var(--spacing-050);

  min-height: 0;

  border-radius: var(--border-radius-150);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu {
  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.search {
  all: unset;

  cursor: text;

  padding: var(--spacing-100);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);

  .search-activator {
    pointer-events: none;
  }

  &:hover .search-activator {
    :deep(input) {
      box-shadow: 0 0 0 1px var(--button-primary-default) inset;
    }
  }

  @media screen and (width <=1440px) {
    .search-activator {
      :deep(input) {
        background-color: transparent;
      }
    }
  }
}
</style>
