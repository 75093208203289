<template>
  <StSidebarItem
    v-model="isActive"
    :label="t('menu.casino')"
    icon="casino"
    :level="1"
    :link="casinoLink"
    with-chevron
    :data-t="`sidebar-item-casino`"
  >
    <StSidebarList :level="2">
      <StSidebarItem
        v-for="tab in topTabsWithProviders"
        :key="tab.link"
        :label="tab.name"
        v-bind="getIconProps(tab)"
        :level="2"
        :link="tab.link"
        :model-value="tab.isActive"
        data-t="sidebar-item-casino-3o7f"
      >
        <StSidebarList v-if="bottomTabs.length" :level="3">
          <StSidebarItem
            v-for="bottomTab in bottomTabs"
            :key="bottomTab.link"
            :label="bottomTab.name"
            v-bind="getIconProps(bottomTab)"
            :level="3"
            :link="bottomTab.link"
            :data-t="`sidebar-item-casino-lvl3-${bottomTab.link
              .split('/')
              .pop()}-mstb`"
          />
        </StSidebarList>
      </StSidebarItem>
    </StSidebarList>
  </StSidebarItem>
</template>

<script setup lang="ts">
import StSidebarList from '@st/ui/components/StSidebarList/StSidebarList.vue'
import StSidebarItem from '@st/ui/components/StSidebarItem/StSidebarItem.vue'
import { type MegaTab, useCasinoTabs } from '../CasinoTabs/useCasinoTabs'

const { t } = useI18n()

const { topTabsWithProviders, bottomTabs } = useCasinoTabs({
  isLobbyEnabled: false,
})

const props = defineProps<{
  modelValue: boolean
}>()

type OpenedMenuItem = 'casino' | null

const emit = defineEmits<{
  (e: 'update:modelValue', value: OpenedMenuItem): void
}>()

function getIconProps(tab: MegaTab) {
  return tab.iconRaw ? { iconRaw: tab.iconRaw } : { icon: tab.icon || 'cherry' }
}

const isActive = computed<boolean>({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value ? 'casino' : null),
})

const route = useRoute()
const casinoLink = computed(() =>
  route.fullPath.includes('/casino') ? route.fullPath : '/casino',
)
</script>
